function Configuration(key) {
  var config = {
    "responsive_people_slideshow" : {
        0: { items: 1 },
        350: { items: 1.2 },
        380: { items: 1.4 },
        420: { items: 1.6 },
        500: { items: 2 },
        530: { items: 2.2 },
        600: { items: 2.5 },
        680: { items: 3 },
        1380: { items: 4 },
    },
    "logo_display_per_page": 8,
  }
  return config[key]
}

export default Configuration;