import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import logo from '../res/logo.svg';
import footer_icon_tel from '../res/icons/footer_icon_tel.svg';
import footer_icon_email from '../res/icons/footer_icon_email.svg';
import footer_icon_fb from '../res/icons/footer_icon_fb.svg';
import footer_icon_ig from '../res/icons/footer_icon_ig.svg';

function Footer() {
  const navigate = useNavigate();

  const navigateTo = (link) => {
       navigate(link);
   }

  useEffect(
    () => {
      
    },
    []
  )

    return (
      <footer className="footer">

        <div className="footer--trademark">
        <img className="footer--trademark--logo" src={logo} alt="Vulcan Coalition"/>
        </div>

        <div className="footer--address">
        <div className="footer--address--text">The Knowledge Exchange 110/1 Krung Thonburi Road, <br/>Banglamphulang, Khlongsan, Bangkok 10600 Thailand</div>
        <div className="footer--address--contact">
          <div className="footer--address--contact--item"><img className="footer--address--contact--item--icon" src={footer_icon_tel} alt="Telephone Number" title="Telephone Number"/>(+66)85-493-8888</div>
          <div className="footer--address--contact--item"><img className="footer--address--contact--item--icon" src={footer_icon_email} alt="E-mail" title="E-mail"/>vulcan@lab.ai</div>
          <div className="footer--address--contact--item"><img className="footer--address--contact--item--icon" src={footer_icon_fb} alt="Facebook Page" title="Facebook Page"/>Vulcan Coalition</div>
          <div className="footer--address--contact--item"><img className="footer--address--contact--item--icon" src={footer_icon_ig} alt="Instragram" title="Instragram"/>Vulcancoalition</div>  
        </div>
        <div className="footer--trademark--text">Copyrights © 2020 All Rights Reserved.</div>
        </div>

      </footer>
    );
}


export default Footer