import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import logo from '../res/logo.svg';
import navbar_responsive_icon from '../res/navbar_responsive_icon.svg';
import navbar_responsive_close from '../res/navbar_responsive_close.svg';
import navbar_mobile_line from '../res/navbar_mobile_line.svg';

function Navbar(props) {
  const isHomePage = props.isHomePage
  const homePageCallback = props.homePageCallback

  const [is_expanded, setIsExpanded] = useState(false)
  const [lastScrollPosition, setlastScrollPosition] = useState(0)

  const navigate = useNavigate();

  const navigateTo = (link, check_page, reload) => {
       navigate(link);
       if (check_page && isHomePage) {
        if (link === "/#section=our_team") {
          homePageCallback("our_team", true)
        } else {
          homePageCallback("root")
        }
        setIsExpanded(false)
       } 
       if (reload) {
        window.location.reload()
       }
   }

  const externalLink = (link) => {
       window.open(link, '_blank').focus();
   }

  const scrollHandle = (e) => {
    setlastScrollPosition(window.scrollY)
  }

  useEffect(
    () => {
      window.addEventListener('scroll', scrollHandle);

      return function cleanup() {
        window.removeEventListener('scroll', scrollHandle);
      };
      
    },
    []
  )

    return (
      <>
          <div className={"navbar "+(lastScrollPosition > 100 || !isHomePage ? 'navbar_filled' : '')}>
          <div className={"navbar_title"} onClick={() => navigateTo("/", true, false)}><img src={logo} className="navbar_logo" alt="Vulcan Coalition (กลับหน้าแรก)"/> </div>
          <div className={lastScrollPosition > 100 || !isHomePage ? 'navbar_title_filled' : 'navbar_title_hidden'} onClick={() => navigateTo("/", true)}><img src={logo} className="navbar_logo" alt="Vulcan Coalition (กลับหน้าแรก)"/> </div>
          {lastScrollPosition <= 100 && isHomePage ? <div className="navbar_hidden_block"></div> : ""}

          <div className="navbar_right">
          <div className="navbar_item" onClick={() => navigateTo("/our_products", false, false)}>Our Products</div>
          <div className="navbar_item" onClick={() => navigateTo("/#section=our_team", true, false)}>Our Team</div>
          <div className="navbar_item" onClick={() => navigateTo("/awards", false, true)}>Awards</div>
          <div className="navbar_item" onClick={() => externalLink("")}>Blog</div>
          <div className="navbar_item" onClick={() => externalLink("https://ran621220.typeform.com/to/Ve7T1Xzl")}>Contact us</div>
          <div className="navbar_menu_button" onClick={() => setIsExpanded(!is_expanded)}>
            <img className={"navbar_mobile_expand_btns " + (!is_expanded ? "" : "smooth_hidden_right")} src={navbar_responsive_icon} alt="Menu" title="Menu"/> 
            <img className={"navbar_mobile_expand_btns navbar_mobile_expand_btns_x " + (!is_expanded ? "smooth_hidden_right" : "")} src={navbar_responsive_close} alt="Close" title="Close"/>
            </div>
          </div>
          </div>

          <div className={"navbar_expands "+ (is_expanded ? "navbar_collapsale" : "smooth_hidden")}>
            <div className={"navbar_expands navbar_collapsale_logo "+(is_expanded ? "" : "hidden")} onClick={() => navigateTo("/", true, false)}><img src={logo} className="navbar_logo" alt="Vulcan Coalition (กลับหน้าแรก)"/> </div>
            <img className={"navbar_expands navbar_collapsale_hr "+(is_expanded ? "" : "hidden")} src={navbar_mobile_line} />
            <div className={"navbar_expands navbar_collapsale_item "+(is_expanded ? "" : "hidden")} onClick={() => navigateTo("/our_products", false, false)}>Our Products</div>
            <div className={"navbar_expands navbar_collapsale_item "+(is_expanded ? "" : "hidden")} onClick={() => navigateTo("/#section=our_team", true, false)}>Our Team</div>
            <div className={"navbar_expands navbar_collapsale_item "+(is_expanded ? "" : "hidden")} onClick={() => navigateTo("/awards", false, true)}>Awards</div>
            <div className={"navbar_expands navbar_collapsale_item "+(is_expanded ? "" : "hidden")} onClick={() => externalLink("")}>Blog</div>
            <div className={"navbar_expands navbar_collapsale_item "+(is_expanded ? "" : "hidden")} onClick={() => externalLink("https://ran621220.typeform.com/to/Ve7T1Xzl")}>Contact us</div>
          </div>
        </>
    );
}


export default Navbar