import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './res/bootstrap.min.css';
import './style.scss';
import Home from './pages/Home';
import ProductsPage from './pages/ProductsPage';
import AwardsPage from './pages/AwardsPage';
import AwardContentDisplayPage from './pages/AwardContentDisplayPage';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/our_products" element={<ProductsPage />} />
        <Route path="/awards" element={<AwardsPage />} />
        <Route path="/award_content" element={<AwardContentDisplayPage />} />
      </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
