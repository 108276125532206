import React, { useState, useEffect , useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Configuration from '../functions/Configuration';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import logo from '../res/logo.svg';

import section0_bg from '../res/backgrounds/awards_page-section0-bg.webp';
import section0_bg_mobile from '../res/backgrounds/awards_page-section0-bg--mobile.webp';

import mini_slideshow_left_arrow from '../res/icons/mini_slideshow_left_arrow.svg';
import mini_slideshow_right_arrow from '../res/icons/mini_slideshow_right_arrow.svg';

function AwardsPage() {
  const [associates_logos, setAssociatesLogos] = useState([[]])
  const [associates_logos_mobile, setAssociatesLogosMobile] = useState([[]])
  const [associates_index, setAssociatesIndex] = useState(0)
  const [associates_index_mobile, setAssociatesIndexMobile] = useState(0)

  const [award_contents, setAwardContents] = useState([])
  const [award_contents_expanded, setAwardContentsExpanded] = useState(false)

  const [certificates_data, setCertificatesData] = useState([[]])
  const [certificates_data_mobile, setCertificatesDataMobile] = useState([[]])
  const [cert_index, setCertIndex] = useState(0)
  const [cert_index_mobile, setCertIndexMobile] = useState(0)

  const navigate = useNavigate();

  const navigateTo = (link) => {
       navigate(link);
   }

  const externalLink = (link) => {
       window.open(link, '_blank').focus();
   }

  useEffect(
    () => {
      window.scrollTo(0,0)
        
      const associates_per_page = 12
      const associates_per_page_mobile = 6

      let associates_dir = require.context('../res/awards_contents/associate_logos/', false, /\.(webp|avif)$/)
      let associates_dirname =  associates_dir.keys()
      let associates_dir_file =  associates_dir.keys().map(associates_dir)
      let associates_new_data = [[]]
      let associates_new_data_mobile = [[]]
      let a_set_id = 0
      let a_set_id_mobile = 0
      for (let a_file_id in associates_dirname) {
        let a_exact_filename = associates_dirname[a_file_id]
        let a_filename = a_exact_filename.substring(6, a_exact_filename.length-5)
        let a_elem = (
          <div className="awards_page__section_associates--inner_content--logo_center_cropper">
            <img className="awards_page__section_associates--inner_content--logo" src={associates_dir_file[a_file_id]} title={a_filename} alt={a_filename}/>
          </div>
          )
        associates_new_data[a_set_id].push(a_elem)
        if ((parseInt(a_file_id)+1) % associates_per_page === 0) {
          //console.log(quota_partners_dirname.length+"divise "+(parseInt(qp_file_id)+1))
          if (parseInt(a_file_id)+1 < associates_dirname.length) {
            a_set_id += 1
            associates_new_data.push([])
          }
        }
        associates_new_data_mobile[a_set_id_mobile].push(a_elem)
        if ((parseInt(a_file_id)+1) % associates_per_page_mobile === 0) {
          //console.log(quota_partners_dirname.length+"divise "+(parseInt(qp_file_id)+1))
          if (parseInt(a_file_id)+1 < associates_dirname.length) {
            a_set_id_mobile += 1
            associates_new_data_mobile.push([])
          }
        }
      }
      setAssociatesLogos(associates_new_data)
      setAssociatesLogosMobile(associates_new_data_mobile)

      //------------------------------

      let awards_content_data = []

      let awards_images_dir = require.context('../res/awards_contents/images/', false, /\.(webp|avif)$/)
      let awards_images_dirname =  awards_images_dir.keys()
      let awards_images_dir_file =  awards_images_dir.keys().map(awards_images_dir)

      let awards_length = awards_images_dirname.length
    
      for (let award_img_file_id in awards_images_dirname) {
        let award_img_exact_filename = awards_images_dirname[award_img_file_id]
        let award_img_filename = award_img_exact_filename.substring(2, award_img_exact_filename.length-5)
        awards_content_data.push({
          image_source: awards_images_dir_file[award_img_file_id],
          file_name: award_img_filename,
        })
      }

      let awards_texts_dir = require.context('../res/awards_contents/text_contents/', false, /\.(txt)$/)
      let awards_texts_dirname =  awards_texts_dir.keys()
      let awards_texts_dir_file =  awards_texts_dir.keys().map(awards_texts_dir)
      for (let txt_file_id in awards_texts_dirname) {

          let exact_filename = awards_texts_dirname[txt_file_id]
          let file_name = exact_filename.substring(2, exact_filename.length-4)
          let file_path = awards_texts_dir_file[txt_file_id]

            fetch(file_path)
            .then((r) => r.text())
            .then(text  => {
              let array_of_chunk_per_line = text.split("\n");

              let title = array_of_chunk_per_line[0]

              let description = array_of_chunk_per_line[2]

              let url_embed = array_of_chunk_per_line[4]

              awards_content_data = awards_content_data.map(object => {
                if (object.file_name === file_name) {
                  return {...object, 
                    title: title,
                    description: description,
                    url_embed: url_embed,
                  };
                }
                return object;
              });

              
            }).then(() => {
              //console.log(awards_content_data)
              if (txt_file_id >= awards_length-1) {
                setAwardContents(awards_content_data)
              }
            })
            
        }

        //------------------------------

      const cert_display = 6;
      const cert_mobile_display = 2;

      let certs_content_data = [[]]
      let certs_content_data_mobile = [[]]
      let cert_set_id = 0
      let cert_set_id_mobile = 0

      let certs_images_dir = require.context('../res/awards_contents/certificates_data/logo_images', false, /\.(webp|avif)$/)
      let certs_images_dirname =  certs_images_dir.keys()
      let certs_images_dir_file =  certs_images_dir.keys().map(certs_images_dir)

      let certs_length = certs_images_dirname.length
      
      let cert_img_set_id = 0
      for (let cert_img_file_id in certs_images_dirname) {
        let cert_img_exact_filename = certs_images_dirname[cert_img_file_id]
        let cert_img_filename = cert_img_exact_filename.substring(2, cert_img_exact_filename.length-5)

        certs_content_data[cert_set_id].push({
          image_source: certs_images_dir_file[cert_img_file_id],
          file_name: cert_img_filename,
        })
        if ((parseInt(cert_img_file_id)+1) % cert_display === 0) {
          //console.log(quota_partners_dirname.length+"divise "+(parseInt(qp_file_id)+1))
          if (parseInt(cert_img_file_id)+1 < certs_images_dirname.length) {
            cert_set_id += 1
            certs_content_data.push([])
          }
        }

        certs_content_data_mobile[cert_set_id_mobile].push({
          image_source: certs_images_dir_file[cert_img_file_id],
          file_name: cert_img_filename,
        })
        if ((parseInt(cert_img_file_id)+1) % cert_mobile_display === 0) {
          //console.log(quota_partners_dirname.length+"divise "+(parseInt(qp_file_id)+1))
          if (parseInt(cert_img_file_id)+1 < certs_images_dirname.length) {
            cert_set_id_mobile += 1
            certs_content_data_mobile.push([])
          }
        }
      }

      let certs_texts_dir = require.context('../res/awards_contents/certificates_data/text_contents/', false, /\.(txt)$/)
      let certs_texts_dirname =  certs_texts_dir.keys()
      let certs_texts_dir_file =  certs_texts_dir.keys().map(certs_texts_dir)
      let cert_set_id_txt = 0
      let cert_set_id_mobile_txt = 0

      for (let txt_file_id in certs_texts_dirname) {

          let exact_filename = certs_texts_dirname[txt_file_id]
          let file_name = exact_filename.substring(2, exact_filename.length-4)
          let file_path = certs_texts_dir_file[txt_file_id]

            fetch(file_path)
            .then((r) => r.text())
            .then(text  => {
              let array_of_chunk_per_line = text.split("\n");

              let title = array_of_chunk_per_line[0]

              let description = array_of_chunk_per_line[2]

              let url = array_of_chunk_per_line[4] ? array_of_chunk_per_line[4] : ""

              certs_content_data[cert_set_id_txt] = certs_content_data[cert_set_id_txt].map(object => {
                if (object.file_name === file_name) {
                  return {...object, 
                    title: title,
                    description: description,
                    url: url,
                  };
                }
                return object;
              });

              if ((parseInt(txt_file_id)+1) % cert_display === 0) {
                //console.log(quota_partners_dirname.length+"divise "+(parseInt(qp_file_id)+1))
                if (parseInt(txt_file_id)+1 < certs_texts_dirname.length) {
                  cert_set_id_txt += 1
                }
              }

              certs_content_data_mobile[cert_set_id_mobile_txt] = certs_content_data_mobile[cert_set_id_mobile_txt].map(object => {
                if (object.file_name === file_name) {
                  return {...object, 
                    title: title,
                    description: description,
                    url: url,
                  };
                }
                return object;
              });

              if ((parseInt(txt_file_id)+1) % cert_mobile_display === 0) {
                //console.log(quota_partners_dirname.length+"divise "+(parseInt(qp_file_id)+1))
                if (parseInt(txt_file_id)+1 < certs_texts_dirname.length) {
                  cert_set_id_mobile_txt += 1
                }
              }

                
              
            }).then(() => {
              //console.log(certs_content_data)
              //console.log(certs_content_data_mobile)
              if (txt_file_id >= certs_length-1) {

                setCertificatesData(certs_content_data)
              }
            }).then(() => {
              //console.log(certs_content_data)
              //console.log(certs_content_data_mobile)
              if (txt_file_id >= certs_length-1) {

                setCertificatesDataMobile(certs_content_data_mobile)
              }
            })
            
        }

    },
    []
  )

    return (
      <>

      <header className="header--awards_page">

        <Navbar isHomePage={false}/>

        <h1 className="header--awards_page--title"> Awards & <br/> Recognition </h1>

        <p className="header--awards_page--description">Hereby list of our accomplishments and good deeds we gave to the society and proven within the lists below.</p>

        <img className="header--awards_page--img" src={section0_bg} />

        <img className="header--awards_page--img--mobile" src={section0_bg_mobile} />

      </header>

      <main className="main_body main_body--award_page">

          <section className="awards_page__section_associates">
            <h4 className="awards_page__section_associates--inner_content--title">Our Associates</h4>
            <div className="awards_page__section_associates--inner_content--logo_container">
              {
                  associates_logos.map((logo_set, index) => {
                    return (
                        <span className={"awards_page__section_associates--inner_content--logo_set "+(associates_index === index ? "active" : (associates_index > index ? "inactive left" : "inactive right"))} key={index}>
                        {logo_set.map((logo, index2) => {
                          return (
                              <span key={index2}>{logo}</span>
                            )
                        })}</span>
                      )
                  })
                }

            </div>
            <div className="awards_page__section_associates--inner_content--logo_container--pagination">
            {associates_logos.length > 1 ? 
                <div className="awards_page__section_associates--inner_content--logo_container--pagination_box">
                  <div className="awards_page__section_associates--inner_content--logo_container--left_arrow" onClick={() => setAssociatesIndex(associates_index > 0 ? associates_index-1 : associates_index)}>
                    <img src={mini_slideshow_left_arrow} alt="left"/></div>
                  <div className="awards_page__section_associates--inner_content--logo_container--numbers">{parseInt(associates_index)+1}/{associates_logos.length}</div>
                  <div className="awards_page__section_associates--inner_content--logo_container--right_arrow" onClick={() => setAssociatesIndex(associates_index < associates_logos.length-1 ? associates_index+1 : associates_index)}>
                    <img src={mini_slideshow_right_arrow} alt="right"/></div>
                </div> : ""}
            </div>

            <div className="awards_page__section_associates--inner_content--logo_container--mobile">
              {
                  associates_logos_mobile.map((logo_set, index) => {
                    return (
                        <span className={"awards_page__section_associates--inner_content--logo_set "+(associates_index_mobile === index ? "active" : (associates_index_mobile > index ? "inactive left" : "inactive right"))} key={index}>
                        {logo_set.map((logo, index2) => {
                          return (
                              <span key={index2}>{logo}</span>
                            )
                        })}</span>
                      )
                  })
                }
            </div>

            <div className="awards_page__section_associates--inner_content--logo_container--mobile--pagination">
            {associates_logos_mobile.length > 1 ? 
                <div className="awards_page__section_associates--inner_content--logo_container--pagination_box">
                  <div className="awards_page__section_associates--inner_content--logo_container--left_arrow" onClick={() => setAssociatesIndexMobile(associates_index_mobile > 0 ? associates_index_mobile-1 : associates_index_mobile)}>
                    <img src={mini_slideshow_left_arrow} alt="left"/></div>
                  <div className="awards_page__section_associates--inner_content--logo_container--numbers">{parseInt(associates_index_mobile)+1}/{associates_logos_mobile.length}</div>
                  <div className="awards_page__section_associates--inner_content--logo_container--right_arrow" onClick={() => setAssociatesIndexMobile(associates_index_mobile < associates_logos_mobile.length-1 ? associates_index_mobile+1 : associates_index_mobile)}>
                    <img src={mini_slideshow_right_arrow} alt="right"/></div>
                </div> : ""}
            </div>

          </section>




          <section className="awards_page__section_contents">

          <div className="awards_page__section_contents--div_contain">
            {
              award_contents.map((key, index) => {
              return (
                  <div className={"awards_page__section_contents--div "+(index >= 12 && !award_contents_expanded ? " hidden " : "")+(index == 11 && !award_contents_expanded ? " quite_hidden " : "")+(index < 3 ? "big" : "small")} 
                  key={index} onClick={() => navigateTo("/award_content#content_id="+key.file_name)}>

                  {index < 3 ? <span className="awards_page__section_contents--container--big">
                    <div className="awards_page__section_contents--img_container">
                    <img className="awards_page__section_contents--big_img" src={key.image_source} alt="image"/>
                    </div>

                    <div className="awards_page__section_contents--title"> {key.title ? key.title : ""} </div>

                    <div className="awards_page__section_contents--des">{key.description ? key.description : ""} </div>
                  
                    </span> :

                    <span className="awards_page__section_contents--container--small">

                    <div className="awards_page__section_contents--img_container--small">
                    <img className="awards_page__section_contents--small_img" src={key.image_source} alt="image"/>
                    </div>

                    <div>
                    <div className="awards_page__section_contents--small_title"> {key.title ? key.title : ""} </div>

                    <div className="awards_page__section_contents--small_des">{key.description ? key.description : ""} </div>
                    </div>
                    </span> }

                  </div>
                )
              })

            }

            </div>

            <div className="awards_page__section_contents--load_more_btn--container">
            {award_contents_expanded ? "" : <button className="awards_page__section_contents--load_more_btn" onClick={() => setAwardContentsExpanded(true)}> Load More </button>}
            </div>

          </section>




          <section className="awards_page__section_certificates">

          <div className="awards_page__section_certificates--inner_content--cert_container">

                {
                  certificates_data.map((cert_set, index) => {
                    //console.log(cert_set)
                    return (
                        <div className={"awards_page__section_certificates--inner_content--cert_set "+(cert_index === index ? "active" : (cert_index > index ? "inactive left" : "inactive right"))} key={index}>
                        {cert_set.map((cert, index2) => {
                          //console.log(cert)
                          return (
                              <div key={index2} className={"awards_page__section_certificates--inner_content--cert "+(cert.url !== "" && cert.url ? "clickable" : "")} 
                              title={cert.url !== "" && cert.url ? "Click for more information" : ""} onClick={() => {
                                if (cert.url !== "" && cert.url) {
                                  externalLink(cert.url)
                                }
                              }}>
                              <div className="awards_page__section_certificates--inner_content--cert--title">{cert.title ? cert.title : ""} </div>

                              <div className="awards_page__section_certificates--inner_content--cert--img--contain">
                              <img className="awards_page__section_certificates--inner_content--cert--img" src={cert.image_source} alt={"certificate_image "+(cert.url !== "" && cert.url ? "clickable_for_more_info" : "")} />
                              </div>

                              <div className="awards_page__section_certificates--inner_content--cert--des">{cert.description ? cert.description : ""}</div>
                              </div>
                            )
                        })}</div>
                      )
                  })
                }
            </div>
            <div className="awards_page__section_certificates--inner_content--cert_container--pagination">
            {certificates_data.length > 1 ? 
                <div className="awards_page__section_certificates--inner_content--logo_container--pagination_box">
                  <div className="awards_page__section_certificates--inner_content--logo_container--left_arrow" onClick={() => setCertIndex(cert_index > 0 ? cert_index-1 : cert_index)}>
                    <img src={mini_slideshow_left_arrow} alt="left"/></div>
                  <div className="awards_page__section_certificates--inner_content--logo_container--numbers">{parseInt(cert_index)+1}/{certificates_data.length}</div>
                  <div className="awards_page__section_certificates--inner_content--logo_container--right_arrow" onClick={() => setCertIndex(cert_index < certificates_data.length-1 ? cert_index+1 : cert_index)}>
                    <img src={mini_slideshow_right_arrow} alt="right"/></div>
                </div> : ""}
            </div>

            <div className="awards_page__section_certificates--inner_content--cert_container--mobile">

                {
                  certificates_data_mobile.map((cert_set, index) => {
                    //console.log(cert_set)
                    return (
                        <div className={"awards_page__section_certificates--inner_content--cert_set "+(cert_index_mobile === index ? "active" : (cert_index_mobile > index ? "inactive left" : "inactive right"))} key={index}>
                        {cert_set.map((cert, index2) => {
                          //console.log(cert)
                          return (
                              <div key={index2} className={"awards_page__section_certificates--inner_content--cert "+(cert.url !== "" && cert.url ? "clickable" : "")} 
                              title={cert.url !== "" && cert.url ? "Click for more information" : ""} onClick={() => {
                                if (cert.url !== "" && cert.url) {
                                  externalLink(cert.url)
                                }
                              }}>
                              <div className="awards_page__section_certificates--inner_content--cert--title">{cert.title ? cert.title : ""} </div>

                              <div className="awards_page__section_certificates--inner_content--cert--img--contain">
                              <img className="awards_page__section_certificates--inner_content--cert--img" src={cert.image_source} alt={"certificate_image "+(cert.url !== "" && cert.url ? "clickable_for_more_info" : "")} />
                              </div>

                              <div className="awards_page__section_certificates--inner_content--cert--des">{cert.description ? cert.description : ""}</div>
                              </div>
                            )
                        })}</div>
                      )
                  })
                }
              </div>
              <div className="awards_page__section_certificates--inner_content--cert_container--mobile--pagination">
                {certificates_data_mobile.length > 1 ? 
                <div className="awards_page__section_certificates--inner_content--logo_container--pagination_box">
                  <div className="awards_page__section_certificates--inner_content--logo_container--left_arrow" onClick={() => setCertIndexMobile(cert_index_mobile > 0 ? cert_index_mobile-1 : cert_index_mobile)}>
                    <img src={mini_slideshow_left_arrow} alt="left"/></div>
                  <div className="awards_page__section_certificates--inner_content--logo_container--numbers">{parseInt(cert_index_mobile)+1}/{certificates_data_mobile.length}</div>
                  <div className="awards_page__section_certificates--inner_content--logo_container--right_arrow" onClick={() => setCertIndexMobile(cert_index_mobile < certificates_data_mobile.length-1 ? cert_index_mobile+1 : cert_index_mobile)}>
                    <img src={mini_slideshow_right_arrow} alt="right"/></div>
                </div> : ""}
              </div>


          </section>


      </main>

      <Footer/>
       
      </>
    );
 // }
}

export default AwardsPage;
