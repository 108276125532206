import React, { useState, useEffect , useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Configuration from '../functions/Configuration';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import GetMultipleRandom from '../functions/GetMultipleRandom';
import VideoLinkConvertToEmbed from '../functions/VideoLinkConvertToEmbed';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import logo from '../res/logo.svg';

function AwardContentDisplayPage() {
  const [award_contents, setAwardContents] = useState([])
  const [award_content_data, setAwardContentData] = useState(undefined)

  const navigate = useNavigate();

  const navigateTo = (link, reload) => {
       navigate(link);

       if (reload) {
        window.location.reload()
        window.scrollTo(0,0)
       }
   }

  const externalLink = (link) => {
       window.open(link, '_blank').focus();
   }

  useEffect(
    () => {
      window.scrollTo(0,0)

      let content_id = GetUrlHashValue("content_id")
      
      let award_content_data = {}
      let awards_content_data = []

      let awards_images_dir = require.context('../res/awards_contents/images/', false, /\.(webp|avif)$/)
      let awards_images_dirname =  awards_images_dir.keys()
      let awards_images_dir_file =  awards_images_dir.keys().map(awards_images_dir)

      let awards_length = awards_images_dirname.length
      
      let award_img_set_id = 0
      for (let award_img_file_id in awards_images_dirname) {
        let award_img_exact_filename = awards_images_dirname[award_img_file_id]
        let award_img_filename = award_img_exact_filename.substring(2, award_img_exact_filename.length-5)
        if (award_img_filename === content_id) {
          award_content_data = {
            ...award_content_data,
            image_source: awards_images_dir_file[award_img_file_id],
            file_name: award_img_filename,
          }
        } else {
          awards_content_data.push({
            image_source: awards_images_dir_file[award_img_file_id],
            file_name: award_img_filename,
          })
        }
        
      }

      let awards_texts_dir = require.context('../res/awards_contents/text_contents/', false, /\.(txt)$/)
      let awards_texts_dirname =  awards_texts_dir.keys()
      let awards_texts_dir_file =  awards_texts_dir.keys().map(awards_texts_dir)
      for (let txt_file_id in awards_texts_dirname) {

          let exact_filename = awards_texts_dirname[txt_file_id]
          let file_name = exact_filename.substring(2, exact_filename.length-4)
          let file_path = awards_texts_dir_file[txt_file_id]

          if (file_name === content_id) {
            fetch(file_path)
            .then((r) => r.text())
            .then(text  => {
              let array_of_chunk_per_line = text.split("\n");

              let title = array_of_chunk_per_line[0]

              let description = array_of_chunk_per_line[2]

              let url_embed = array_of_chunk_per_line[4]

              let true_url_embed = array_of_chunk_per_line[6] ? array_of_chunk_per_line[6] : ""

              award_content_data = {
                ...award_content_data,
                    title: title,
                    description: description,
                    url_embed: url_embed,
                    display_url_embed: true_url_embed === "" ? VideoLinkConvertToEmbed(url_embed) : true_url_embed,
              }

                //console.log(awards_content_data)
                setAwardContentData(award_content_data)
              
            }) 

          } else {
            fetch(file_path)
            .then((r) => r.text())
            .then(text  => {
              let array_of_chunk_per_line = text.split("\n");

              let title = array_of_chunk_per_line[0]

              let description = array_of_chunk_per_line[2]

              let url_embed = array_of_chunk_per_line[4]

              awards_content_data = awards_content_data.map(object => {
                if (object.file_name === file_name) {
                  return {...object, 
                    title: title,
                    description: description,
                    url_embed: url_embed,
                  };
                }
                return object;
              });

              
            }).then(() => {
              //console.log(awards_content_data)
              if (txt_file_id >= awards_length-1) {
                setAwardContents(GetMultipleRandom(awards_content_data, 4))
              }
            })
          }

             
            
        }

    },
    []
  )

    return (
      <>

      <header className="header--award_content_page">

        <Navbar isHomePage={false}/>

      </header>

      <main className="main_body award_content_page__main_body">

          {award_content_data ? 

          <section className="award_content_page__main">

          <div className="award_content_page__main--title_head">
          <h4 className="award_content_page__main--title_head--left" onClick={() => navigateTo("/awards", true)}>Awards and Recognition</h4>
          <h4 className="award_content_page__main--title_head--left--mobile" onClick={() => navigateTo("/awards", true)}>Awards...</h4>
          <h4 className="award_content_page__main--title_head--center">{">"}</h4> <h4 className="award_content_page__main--title_head--right">{award_content_data.title ? award_content_data.title : ""}</h4>
          </div>

          <h2 className="award_content_page__main--title_main">{award_content_data.title ? award_content_data.title : ""}</h2>

          <div className={"award_content_page__main--embed_contain "+(award_content_data.display_url_embed ? "video" : "image")}>
          {award_content_data.display_url_embed ? 
                      <iframe title="Embed Video" className="award_content_page__main--embed--video" src={award_content_data.display_url_embed} 
                      allowFullScreen="true" scrolling="no" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> : 
                      <img title="Click for more information." className="award_content_page__main--embed--image" 
                      src={award_content_data.image_source} alt="click image for more info" onClick={() => externalLink(award_content_data.url_embed)}/>}
          </div>

          <div className="award_content_page__main--details">
            {award_content_data.description ? award_content_data.description : ""}
          </div>
                    

          </section> : ""}


          <section className="award_content_page__recommendation">

            <h4 className="award_content_page__recommendation--big_title">Recommended from Vulcan</h4>

            <div className="award_content_page__recommendation--div_contain">

            {
              award_contents.map((key, index) => {
              return (
                  <div className={"award_content_page__recommendation--div"} 
                  key={index} onClick={() => navigateTo("/award_content#content_id="+key.file_name, true)}>

                  <span className="award_content_page__recommendation--container">

                    <div className="award_content_page__recommendation--img_container">
                    <img className="award_content_page__recommendation--_img" src={key.image_source} alt="image"/>
                    </div>

                    <div>
                    <div className="award_content_page__recommendation--title"> {key.title ? key.title : ""} </div>

                    <div className="award_content_page__recommendation--des">{key.description ? key.description : ""} </div>
                    </div>
                    </span>

                  </div>
                )
              })

            }

            </div>

          </section>


      </main>

      <Footer/>
       
      </>
    );
 // }
}

export default AwardContentDisplayPage;
