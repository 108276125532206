function VideoLinkConvertToEmbed(raw_link, is_already_embed) {
  if (raw_link.includes("youtube.com")) {
    var cut_short_true_link = raw_link
    if (raw_link.includes("&")) { //prevent aliening url hashes
      cut_short_true_link = (raw_link.split("&"))[0]
    }
    if (cut_short_true_link.includes("watch?v=")) {
      return cut_short_true_link.replace("watch?v=", "embed/")
    } else {
      return cut_short_true_link
    }
  }




  if (is_already_embed) {
    return raw_link
  } else {
    return null
  }
  
  
}

export default VideoLinkConvertToEmbed;