import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Configuration from '../functions/Configuration';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import logo from '../res/logo.svg';
import section0_income_per_person from '../res/icons/section0-income_per_person.svg';
import section0_quota_partners from '../res/icons/section0-quota_partners.svg';
import section0_workforce_quotas from '../res/icons/section0-workforce_quotas.svg';

import section1_chage_to_for from '../res/icons/section1-chage_to_for.webp';
import section1_change_to_for_responsive from '../res/icons/section1-change_to_for_responsive.webp';
import section1_change_to_for_responsive_words from '../res/icons/section1-change_to_for_responsive_words.svg';

import section1_innovation from '../res/icons/section1-innovation.webp';
import section1_job from '../res/icons/section1-job.webp';
import section1_sustainability from '../res/icons/section1-sustainability.webp';

import section2_blind from '../res/icons/section2-blind.svg';
import section2_deaf from '../res/icons/section2-deaf.svg';
import section2_autistic from '../res/icons/section2-autistic.svg';

import section3_logo_maywe from '../res/products_slideshow/section3-maywe-logo.webp';
import section3_logo_voice_ai from '../res/products_slideshow/section3-voice_ai-logo.webp';
import section3_logo_home_automation from '../res/products_slideshow/section3-home_automation-logo.webp';
import section3_logo_live_chat from '../res/products_slideshow/section3-live_chat-logo.webp';
import slideshow_arrow_left from '../res/slideshow_arrow_left.svg';
import slideshow_arrow_right from '../res/slideshow_arrow_right.svg';

import section5_item_hr from '../res/icons/section5-item_hr.svg';

import disability_team from '../res/people_slideshow/disability_team.webp';
import disability_team__mobile from '../res/people_slideshow/disability_team__mobile.webp';

import mini_slideshow_left_arrow from '../res/icons/mini_slideshow_left_arrow.svg';
import mini_slideshow_right_arrow from '../res/icons/mini_slideshow_right_arrow.svg';

import mini_slideshow_left_arrow_people from '../res/icons/mini_slideshow_left_arrow_people.svg';
import mini_slideshow_right_arrow_people from '../res/icons/mini_slideshow_right_arrow_people.svg';

import sdg_icon from '../res/sdg_icon.webp';
import sdg_details from '../res/sdg_details.webp';

function Home() {
  const [change_to_for_title, setCTFTitle] = useState("")
  const [change_to_for_words, setCTFWords] = useState("")
  const [sdg_title_alt, setSDGTitle] = useState("")
  const [sdg_details_alt, setSDGDetails] = useState("")

  const [slideshow_products, setSlideShowProducts] = useState([])
  const [slideshow_products_index, setSlideShowProductsIndex] = useState(0)

  const [quota_logos, setQuotaLogos] = useState([])
  const [partnership_logos, setPartnershipLogos] = useState([])
  const [customer_logos, setCustomerLogos] = useState([])
  const [quota_logos_mobile, setQuotaLogosMobile] = useState([[]])
  const [partnership_logos_mobile, setPartnershipLogosMobile] = useState([[]])
  const [customer_logos_mobile, setCustomerLogosMobile] = useState([[]])

  const [quota_index, setQuotaIndex] = useState(0)
  const [partnership_index, setPartnershipIndex] = useState(0)
  const [customer_index, setCustomerIndex] = useState(0)

  const [slideshow_people, setSlideShowPeople] = useState([])
  const [slideshow_people_render, setSlideShowPeopleRender] = useState([])

  const [header_card_focus, setHeaderCardFocus] = useState(false)
  const [section_1_title_focus, setSection1TitleFocus] = useState(false)
  const [section_1_info_focus, setSection1InfoFocus] = useState(false)
  const [section_2_focus, setSection2Focus] = useState(false)

  const scrollToPeopleSection = useRef();

  const navigate = useNavigate();

  const [header_texts, setHeaderTexts] = useState(undefined)
  const [section1_texts, setSection1Texts] = useState(undefined)
  const [section2_texts, setSection2Texts] = useState(undefined)
  const [section3_texts, setSection3Texts] = useState(undefined)
  const [section4_texts, setSection4Texts] = useState(undefined)
  const [section5_texts, setSection5Texts] = useState(undefined)
  const [section6_texts, setSection6Texts] = useState(undefined)

  const navigateTo = (link) => {
       navigate(link);
   }

  const externalLink = (link) => {
       window.open(link, '_blank').focus();
   }

  const plusSlides = (next_int) => {
    const next_index = slideshow_products_index+next_int
    if (next_index < 0 || next_index >= slideshow_products.length) {
      return
    }
    setSlideShowProductsIndex(next_index)
  }

  const currentSlide = (next_index) => {
    if (next_index < 0 || next_index >= slideshow_products.length) {
      return
    }
    setSlideShowProductsIndex(next_index)
  }

  const scrollHandle = (e) => {
    try {
        let windowHeight = window.innerHeight;
        let elementVisible = 150;

        let section1title = document.querySelectorAll(".section_story");
        let section1titleTop = section1title[0].getBoundingClientRect().top;

        if (section1titleTop < windowHeight - elementVisible) {
          setSection1TitleFocus(true)
        } else {
          setSection1TitleFocus(false)
        }

        let section1info = document.querySelectorAll(".section_story--story");
        let section1infoTop = section1info[0].getBoundingClientRect().top;

        if (section1infoTop < windowHeight - elementVisible) {
          setSection1InfoFocus(true)
        } else {
          setSection1InfoFocus(false)
        }

        let section2 = document.querySelectorAll(".section_believe_in_disabled");
        let section2Top = section2[0].getBoundingClientRect().top;

        if (section2Top < windowHeight - elementVisible) {
          setSection2Focus(true)
        } else {
          setSection2Focus(false)
        }
      } catch (e) {

      }
        
      
  }

  const renderDotsItem = ({ isActive }) => {
    return <span className={"carosel_dot "+(isActive ? "active" : "")}></span>
  }

  const renderDotsItemPeople = ({ isActive }) => {
    return <span className={"carosel_dot--people "+(isActive ? "active" : "")}></span>
  }

  const renderPrevButtonPeople = ({ isDisabled }) => {
    return <button className={"carosel_dot--arrow_btn "+(isDisabled ? "disabled" : "")} disabled={isDisabled}><img src={mini_slideshow_left_arrow_people} alt="left"/></button>
  };

  const renderNextButtonPeople = ({ isDisabled }) => {
      return <button className={"carosel_dot--arrow_btn "+(isDisabled ? "disabled" : "")} disabled={isDisabled}><img src={mini_slideshow_right_arrow_people} alt="right"/></button>
  };

  const goToSection = (section, fix_scroll_error) => {
        window.scrollTo(0,0)
        if (section === "our_team") {
          scrollToPeopleSection.current.scrollIntoView()
          if (fix_scroll_error) {
            setTimeout(() => {
              scrollToPeopleSection.current.scrollIntoView()
            }, 1000)
          }
          
        } 
  }

  useEffect(
    () => {
      let section_tag = GetUrlHashValue("section")
      if (section_tag) {
        goToSection(section_tag, true)
      } else {
        window.scrollTo(0,0)
      }

      let text_files_dir = require.context('../res/texts/', false, /\.(txt)$/)
      let text_files_dirname =  text_files_dir.keys()
      let text_files_dir_file =  text_files_dir.keys().map(text_files_dir)
      for (let txt_file_id in text_files_dirname) {

          let file_name = text_files_dirname[txt_file_id]
          let file_path = text_files_dir_file[txt_file_id]

            fetch(file_path)
            .then((r) => r.text())
            .then(text  => {
              let array_of_chunk_per_line = text.split("\n");

              if (file_name === "./main_page__section_0__header_section_texts.txt") {
                setHeaderTexts(array_of_chunk_per_line);
              } else if (file_name === "./main_page__section_1__story_section_texts.txt") {
                setSection1Texts(array_of_chunk_per_line);
                setCTFTitle(array_of_chunk_per_line[9]);
                setCTFWords(`
${array_of_chunk_per_line[10]}
${array_of_chunk_per_line[11]}
${array_of_chunk_per_line[12]}`
                  ); //sorry, it has to be un-indented like this, for alt text
              } else if (file_name === "./main_page__section_2__disabled_abilities_section_texts.txt") {
                setSection2Texts(array_of_chunk_per_line);
              } else if (file_name === "./main_page__section_3__products_slideshow_section_texts.txt") {
                setSection3Texts(array_of_chunk_per_line);
                setSlideShowProducts([
    {
      logo_url: section3_logo_maywe,
      bg_class: "section_products_slideshow--bg1",
      name: array_of_chunk_per_line[0],
      quote: array_of_chunk_per_line[1],
      description: array_of_chunk_per_line[2],
      learn_more_url: "/our_products#product=maywe",
    },
    {
      logo_url: section3_logo_voice_ai,
      bg_class: "section_products_slideshow--bg2",
      name: array_of_chunk_per_line[4],
      quote: array_of_chunk_per_line[5],
      description: array_of_chunk_per_line[6],
      learn_more_url: "/our_products#product=voice_ai",
    },
    {
      logo_url: section3_logo_home_automation,
      bg_class: "section_products_slideshow--bg3",
      name: array_of_chunk_per_line[8],
      quote: array_of_chunk_per_line[9],
      description: array_of_chunk_per_line[10],
      learn_more_url: "/our_products#product=home_automation",
    },
    {
      logo_url: section3_logo_live_chat,
      bg_class: "section_products_slideshow--bg4",
      name: array_of_chunk_per_line[12],
      quote: array_of_chunk_per_line[13],
      description: array_of_chunk_per_line[14],
      learn_more_url: "/our_products#product=live_chat",
    },
                ]);
              } else if (file_name === "./main_page__section_4__quota_partners_section_texts.txt") {
                setSection4Texts(array_of_chunk_per_line);
              } else if (file_name === "./main_page__section_5__people_slideshow_section_texts.txt") {
                setSection5Texts(array_of_chunk_per_line);

                let slideshow_people_raw = [ 
                    {
                      bg_class: "section_people_slideshow--item--bg01",
                      role: array_of_chunk_per_line[5],
                      name: array_of_chunk_per_line[6],
                    },
                    {
                      bg_class: "section_people_slideshow--item--bg02",
                      role: array_of_chunk_per_line[8],
                      name: array_of_chunk_per_line[9],
                    },
                    {
                      bg_class: "section_people_slideshow--item--bg03",
                      role: array_of_chunk_per_line[11],
                      name: array_of_chunk_per_line[12],
                    },
                    {
                      bg_class: "section_people_slideshow--item--bg04",
                      role: array_of_chunk_per_line[14],
                      name: array_of_chunk_per_line[15],
                    },
                    {
                      bg_class: "section_people_slideshow--item--bg05",
                      role: array_of_chunk_per_line[17],
                      name: array_of_chunk_per_line[18],
                    },
                    {
                      bg_class: "section_people_slideshow--item--bg06",
                      role: array_of_chunk_per_line[20],
                      name: array_of_chunk_per_line[21],
                    },
                    {
                      bg_class: "section_people_slideshow--item--bg07",
                      role: array_of_chunk_per_line[23],
                      name: array_of_chunk_per_line[24],
                    },
                    {
                      bg_class: "section_people_slideshow--item--bg08",
                      role: array_of_chunk_per_line[26],
                      name: array_of_chunk_per_line[27],
                    },
                    {
                      bg_class: "section_people_slideshow--item--bg09",
                      role: array_of_chunk_per_line[29],
                      name: array_of_chunk_per_line[30],
                    },
                    {
                      bg_class: "section_people_slideshow--item--bg10",
                      role: array_of_chunk_per_line[32],
                      name: array_of_chunk_per_line[33],
                    },
                ]

                setSlideShowPeople(slideshow_people_raw)

                let slideshow_people_arr = []
                for (let people_obj_index in slideshow_people_raw) {
                  let people_obj = slideshow_people_raw[people_obj_index]
                  slideshow_people_arr.push(
                      <span data-value={people_obj_index}>
                        <div className={"section_people_slideshow--item"}>
                        <div className={"section_people_slideshow--item--bg "+people_obj.bg_class}>
                        </div>
                        <div className="section_people_slideshow--item--textbox">
                          <div className="section_people_slideshow--item--role">{people_obj.role}</div>
                        </div>
                        {people_obj.name !== "" ? <img className="section_people_slideshow--item--hr" alt="separation_line" src={section5_item_hr} /> : ""}
                          <p className="section_people_slideshow--item--name">{people_obj.name}</p>
                        </div>
                      </span>
                    )
                }
                setSlideShowPeopleRender(slideshow_people_arr)
              } else if (file_name === "./main_page__section_6__sdg_section_texts.txt") {
                setSection6Texts(array_of_chunk_per_line);
                setSDGTitle(array_of_chunk_per_line[0]);
                setSDGDetails(`
${array_of_chunk_per_line[1]}
${array_of_chunk_per_line[2]}
${array_of_chunk_per_line[3]}
${array_of_chunk_per_line[4]}
${array_of_chunk_per_line[5]}
${array_of_chunk_per_line[6]}`
                  );
              }
              
            })  

      }

      let quota_partners_dir = require.context('../res/partners/quota_partners/', false, /\.(webp|avif)$/)
      let quota_partners_dirname =  quota_partners_dir.keys()
      let quota_partners_dir_file =  quota_partners_dir.keys().map(quota_partners_dir)
      let quota_partners_new_data = []
      let quota_partners_new_data_mobile = [[]]
      let qp_set_id = 0
      for (let qp_file_id in quota_partners_dirname) {
        let qp_exact_filename = quota_partners_dirname[qp_file_id]
        let qp_filename = qp_exact_filename.substring(6, qp_exact_filename.length-5)
        let qp_elem = (
          <span data-value={qp_file_id}>
          <div className="section_quota_partners--inner_content--logo_center_cropper">
            <img className="section_quota_partners--inner_content--logo" src={quota_partners_dir_file[qp_file_id]} title={qp_filename} alt={qp_filename}/>
          </div>
          </span>
          )
        quota_partners_new_data.push(qp_elem)
        quota_partners_new_data_mobile[qp_set_id].push(qp_elem)
        if ((parseInt(qp_file_id)+1) % Configuration("logo_display_per_page") === 0) {
          //console.log(quota_partners_dirname.length+"divise "+(parseInt(qp_file_id)+1))
          if (parseInt(qp_file_id)+1 < quota_partners_dirname.length) {
            qp_set_id += 1
            quota_partners_new_data_mobile.push([])
          }
        }
      }
      setQuotaLogos(quota_partners_new_data)
      setQuotaLogosMobile(quota_partners_new_data_mobile)

      let partnerships_dir = require.context('../res/partners/partnerships/', false, /\.(webp|avif)$/)
      let partnerships_dirname =  partnerships_dir.keys()
      let partnerships_dir_file =  partnerships_dir.keys().map(partnerships_dir)
      let partnerships_new_data = []
      let partnerships_new_data_mobile = [[]]
      let p_set_id = 0
      for (let p_file_id in partnerships_dirname) {
        let p_exact_filename = partnerships_dirname[p_file_id]
        let p_filename = p_exact_filename.substring(6, p_exact_filename.length-5)
        let p_elem = (
          <span data-value={p_file_id}>
          <div className="section_quota_partners--inner_content--logo_center_cropper">
            <img className="section_quota_partners--inner_content--logo" src={partnerships_dir_file[p_file_id]} title={p_filename} alt={p_filename}/>
          </div>
          </span>
          )
        partnerships_new_data.push(p_elem)
        partnerships_new_data_mobile[p_set_id].push(p_elem)
        if ((parseInt(p_file_id)+1) % Configuration("logo_display_per_page") === 0) {
          //console.log(partnerships_dirname.length+"divise "+(parseInt(p_file_id)+1))
          if (parseInt(p_file_id)+1 < partnerships_dirname.length) {
            p_set_id += 1
            partnerships_new_data_mobile.push([])
          }
        }
      }
      setPartnershipLogos(partnerships_new_data)
      setPartnershipLogosMobile(partnerships_new_data_mobile)

      let customers_dir = require.context('../res/partners/customers/', false, /\.(webp|avif)$/)
      let customers_dirname =  customers_dir.keys()
      let customers_dir_file =  customers_dir.keys().map(customers_dir)
      let customers_new_data = []
      let customers_new_data_mobile = [[]]
      let c_set_id = 0
      for (let c_file_id in customers_dirname) {
        let c_exact_filename = customers_dirname[c_file_id]
        let c_filename = c_exact_filename.substring(6, c_exact_filename.length-5)
        let c_elem = (
          <span data-value={c_file_id}>
          <div className="section_quota_partners--inner_content--logo_center_cropper">
            <img className="section_quota_partners--inner_content--logo" src={customers_dir_file[c_file_id]} title={c_filename} alt={c_filename}/>
          </div>
          </span>
          )
        customers_new_data.push(c_elem)
        customers_new_data_mobile[c_set_id].push(c_elem)
        if ((parseInt(c_file_id)+1) % Configuration("logo_display_per_page") === 0) {
          //console.log(customers_dirname.length+"divise "+(parseInt(c_file_id)+1))
          if (parseInt(c_file_id)+1 < customers_dirname.length) {
            c_set_id += 1
            customers_new_data_mobile.push([])
          }
        }
      }
      setCustomerLogos(customers_new_data)
      setCustomerLogosMobile(customers_new_data_mobile)

      setHeaderCardFocus(true)

      window.addEventListener("scroll", scrollHandle);

      /*const timer = window.setInterval(() => {
          setSlideShowProductsIndex(slideshow_index => slideshow_index < slideshow_products.length-1 ? slideshow_index + 1 : 0);
      }, 10000);*/

      return function cleanup() {
        window.removeEventListener('scroll', scrollHandle);
        //window.clearInterval(timer);
      };
    },
    []
  )

  //https://stackoverflow.com/questions/53824116/react-hooks-usestate-array

    return (
      <>

      <div className="header">

        <Navbar isHomePage={true} homePageCallback={goToSection}/>

        <div className="title_box">

          <div className={header_card_focus ? "title_box--display" : "opacity_hidden"}>
          <img className="title_box--display--logo" src={logo} alt="Vulcan Coalition"/>

          <br/>

          <div className="title_box--display--description">{header_texts ? header_texts[0] : ""}</div>

          <div className="title_box--display--buttons_box">
            <button className="title_box--display--button" onClick={() => {}}>
            {header_texts ? header_texts[1] : ""}
            </button>
            <button className="title_box--display--button2" onClick={() => externalLink("https://ran621220.typeform.com/to/Ve7T1Xzl")}>
            {header_texts ? header_texts[2] : ""}
            </button>
          </div>

          </div>

        </div>

        <div className="header_cards_box">

          <div className={header_card_focus ? "header_card" : "opacity_hidden"}>
          <img className="header_card--logo" src={section0_quota_partners} alt="quota partners"/>
            <div className="header_card--textbox">
              <div className="header_card--textbox--top">
              {header_texts ? header_texts[3] : ""}
              </div>
              <div className="header_card--textbox-bottom">
              {header_texts ? header_texts[4] : ""}
              </div>
            </div>
          </div>
          <div className={header_card_focus ? "header_card" : "opacity_hidden"}>
          <img className="header_card--logo" src={section0_workforce_quotas} alt="workforce quotas"/>
            <div className="header_card--textbox">
              <div className="header_card--textbox--top">
              {header_texts ? header_texts[5] : ""}
              </div>
              <div className="header_card--textbox-bottom">
              {header_texts ? header_texts[6] : ""}
              </div>
            </div>
          </div>
          <div className={header_card_focus ? "header_card" : "opacity_hidden"}>
          <img className="header_card--logo" src={section0_income_per_person} alt="income per person"/>
            <div className="header_card--textbox">
              <div className="header_card--textbox--top">
              {header_texts ? header_texts[7] : ""}<span className="header_card--textbox--top--unit">{header_texts ? header_texts[8] : ""}</span>
              </div>
              <div className="header_card--textbox-bottom">
              {header_texts ? header_texts[9] : ""}
              </div>
            </div>
          </div>

        </div>


      </div>

      <main className="main_body">

          <section className="section_story">

            <div className="section_story--top_margin"></div>

            <div className={section_1_title_focus ? "section_story--intro_box" : "vertical_opacity_hidden"}>

              <div className="section_story--intro_box--item">
                <div className="section_story--intro_box--item--bg section_story--intro_box--item--bg1">
                </div>
                <div className="section_story--intro_box--item--textbox">
                  <div className="section_story--intro_box--title">{section1_texts ? section1_texts[0] : ""}</div>
                  <p className="section_story--intro_box--description">{section1_texts ? section1_texts[1] : ""}</p>
                </div>
              </div>
              <div className="section_story--intro_box--item">
                <div className="section_story--intro_box--item--bg section_story--intro_box--item--bg2">
                </div>
                <div className="section_story--intro_box--item--textbox">
                  <div className="section_story--intro_box--title">{section1_texts ? section1_texts[2] : ""}</div>
                  <p className="section_story--intro_box--description">{section1_texts ? section1_texts[3] : ""}</p>
                </div>
              </div>
              <div className="section_story--intro_box--item">
                <div className="section_story--intro_box--item--bg section_story--intro_box--item--bg3">
                </div>
                <div className="section_story--intro_box--item--textbox">
                  <div className="section_story--intro_box--title">{section1_texts ? section1_texts[4] : ""}</div>
                  <p className="section_story--intro_box--description">{section1_texts ? section1_texts[5] : ""}</p>
                </div> 
              </div>

            </div>

            <div className="section_story--story">

              <h1 className="section_story--story--title">{section1_texts ? section1_texts[7] : ""}</h1>
              <div className="section_story--story--description">{section1_texts ? section1_texts[8] : ""}</div>

              <div className={section_1_info_focus ? "section_story--story--responsive--change_to_for" : "vertical_opacity_hidden"}>
                  <img className="section_story--story--responsive--change_to_for--img" src={section1_change_to_for_responsive} alt={change_to_for_title} title={change_to_for_title}/>
                  <br/>
                  <img className="section_story--story--responsive--change_to_for--words" src={section1_change_to_for_responsive_words} alt={change_to_for_words} title={change_to_for_words}/>
                </div>

              <div className={section_1_info_focus ? "section_story--story--infobox" : "vertical_opacity_hidden"}>

                <div className="section_story--story--infobox--change_to_for">
                  <img className="section_story--story--infobox--change_to_for--img" src={section1_chage_to_for} alt={change_to_for_title+" "+change_to_for_words} title={change_to_for_title+" "+change_to_for_words}/>
                </div>

                <div className="section_story--story--infobox--display">

                  <div className="section_story--story--infobox--title">{section1_texts ? section1_texts[14] : ""}</div>
                  <div className="section_story--story--infobox--description">{section1_texts ? section1_texts[15] : ""}</div>

                  <div className="section_story--story--infobox--display--howbox">
                      <img className="section_story--story--infobox--display--howbox--logo" src={section1_innovation} alt="placeholder"/>
                      <div className="section_story--story--infobox--display--howbox--textbox">
                        <div className="section_story--story--infobox--display--howbox--title">{section1_texts ? section1_texts[16] : ""}</div>
                        <div className="section_story--story--infobox--display--howbox--description">{section1_texts ? section1_texts[17] : ""}</div>
                      </div>
                  </div>

                  <div className="section_story--story--infobox--display--howbox">
                      <img className="section_story--story--infobox--display--howbox--logo" src={section1_job} alt="placeholder"/>
                      <div className="section_story--story--infobox--display--howbox--textbox">
                        <div className="section_story--story--infobox--display--howbox--title">{section1_texts ? section1_texts[18] : ""}</div>
                        <div className="section_story--story--infobox--display--howbox--description">{section1_texts ? section1_texts[19] : ""}</div>
                      </div>
                  </div>

                  <div className="section_story--story--infobox--display--howbox">
                       <img className="section_story--story--infobox--display--howbox--logo" src={section1_sustainability} alt="placeholder"/>
                      <div className="section_story--story--infobox--display--howbox--textbox">
                        <div className="section_story--story--infobox--display--howbox--title">{section1_texts ? section1_texts[20] : ""}</div>
                        <div className="section_story--story--infobox--display--howbox--description">{section1_texts ? section1_texts[21] : ""}</div>
                      </div>
                  </div>

                  <div className="section_story--story--infobox--display--sidenote">
                  {section1_texts ? section1_texts[23] : ""}
                  </div>

                </div>
              </div>

            </div>

          </section>





          <section className="section_believe_in_disabled">
            <h4 className="section_believe_in_disabled--title">{section2_texts ? section2_texts[0] : ""}</h4>
            <div className="section_believe_in_disabled--des">{section2_texts ? section2_texts[1] : ""}</div>
            <div className="section_believe_in_disabled--types_container">

              <div className={section_2_focus ? "section_believe_in_disabled--type" : "vertical_opacity_hidden"}>
               <img className="section_believe_in_disabled--logo" src={section2_blind} alt="placeholder"/>
                <div className="section_believe_in_disabled--textbox">
                  <div className="section_believe_in_disabled--textbox--top">
                  {section2_texts ? section2_texts[2] : ""}
                  </div>
                  <div className="section_believe_in_disabled--textbox-bottom">
                  {section2_texts ? section2_texts[3] : ""}
                  </div>
                </div>
              </div>

              <div className={section_2_focus ? "section_believe_in_disabled--type" : "vertical_opacity_hidden"}>
               <img className="section_believe_in_disabled--logo" src={section2_deaf} alt="placeholder"/>
                <div className="section_believe_in_disabled--textbox">
                  <div className="section_believe_in_disabled--textbox--top">
                  {section2_texts ? section2_texts[4] : ""}
                  </div>
                  <div className="section_believe_in_disabled--textbox-bottom">
                  {section2_texts ? section2_texts[5] : ""}
                  </div>
                </div>
              </div>

              <div className={section_2_focus ? "section_believe_in_disabled--type" : "vertical_opacity_hidden"}>
               <img className="section_believe_in_disabled--logo" src={section2_autistic} alt="placeholder"/>
                <div className="section_believe_in_disabled--textbox">
                  <div className="section_believe_in_disabled--textbox--top">
                  {section2_texts ? section2_texts[6] : ""}
                  </div>
                  <div className="section_believe_in_disabled--textbox-bottom">
                  {section2_texts ? section2_texts[7] : ""}
                  </div>
                </div>
              </div>

            </div>
          </section>





          <section className={"section_products_slideshow"}>

          <div className="section_products_slideshow--bg_part">
          {
            slideshow_products.map((key, index) => {
              return (
                  <div className={"section_products_slideshow--bg "+key.bg_class+(index === slideshow_products_index ? " active" : (index < slideshow_products_index ? " inactive_left" : " inactive_right"))} key={index}>
                  </div>
                )
            })
          }
          </div>

          <div className="section_products_slideshow--top_part">
          {
            slideshow_products.map((key, index) => {
              return (
                  <div className={(index === slideshow_products_index ? "section_products_slideshow--slide-active" : ("section_products_slideshow--slide-inactive"+(index < slideshow_products_index ? " inactive_left" : " inactive_right")))} key={index}>

                  <img className="section_products_slideshow--logo" alt={key.name+"'s logo"} src={key.logo_url}/>

                  <h3 className="section_products_slideshow--name">{key.name}</h3>

                  <div className="section_products_slideshow--quote">{key.quote}</div>

                  <div className="section_products_slideshow--description">{key.description}</div>

                  <button className="section_products_slideshow--button" onClick={() => navigateTo(key.learn_more_url)}>
                  Learn more &#8594;
                  </button>
                  
                  </div>
                )
            })
          }

          <span className="section_products_slideshow--prev" onClick={() => plusSlides(-1)}><img src={slideshow_arrow_left} alt="previous button" /></span>
          <span className="section_products_slideshow--next" onClick={() => plusSlides(1)}><img src={slideshow_arrow_right} alt="next button" /></span>
          </div>

          <div className="section_products_slideshow--bottom_part">
          {
            slideshow_products.map((key, index) => {
              return (
                  <span key={index} className={"section_products_slideshow--dot "+(index === slideshow_products_index ? "active" : "")} onClick={() => currentSlide(index)}></span>
                )
            })
          }
          </div>

          </section>




          <section className="section_quota_partners">

            <div className="section_quota_partners--inner_content">
              <h4 className="section_quota_partners--inner_content--title">{section4_texts ? section4_texts[0] : ""}</h4>
              <div className="section_quota_partners--inner_content--description">{section4_texts ? section4_texts[1] : ""}</div>
              <div className="section_quota_partners--inner_content--logo_container">

                {
                  quota_logos.map((logo, index) => {
                    return (
                        <span key={index}>{logo}</span>
                      )
                  })
                }

              </div>
              <div className="section_quota_partners--inner_content--logo_container--mobile">

                {
                  quota_logos_mobile.map((logo_set, index) => {
                    return (
                        <span className={"section_quota_partners--inner_content--logo_set "+(quota_index === index ? "active" : (quota_index > index ? "inactive left" : "inactive right"))} key={index}>
                        {logo_set.map((logo, index2) => {
                          return (
                              <span key={index2}>{logo}</span>
                            )
                        })}</span>
                      )
                  })
                }
                {quota_logos_mobile.length > 1 ? 
                <div className="section_quota_partners--inner_content--logo_container--pagination_box">
                  <div className="section_quota_partners--inner_content--logo_container--left_arrow" onClick={() => setQuotaIndex(quota_index > 0 ? quota_index-1 : quota_index)}>
                    <img src={mini_slideshow_left_arrow} alt="left"/></div>
                  <div className="section_quota_partners--inner_content--logo_container--numbers">{parseInt(quota_index)+1}/{quota_logos_mobile.length}</div>
                  <div className="section_quota_partners--inner_content--logo_container--right_arrow" onClick={() => setQuotaIndex(quota_index < quota_logos_mobile.length-1 ? quota_index+1 : quota_index)}>
                    <img src={mini_slideshow_right_arrow} alt="right"/></div>
                </div> : ""}

              </div>
            </div>

            <div className="section_quota_partners--inner_content">
              <h4 className="section_quota_partners--inner_content--title">{section4_texts ? section4_texts[2] : ""}</h4>
              <div className="section_quota_partners--inner_content--description">{section4_texts ? section4_texts[3] : ""}</div>
              <div className="section_quota_partners--inner_content--logo_container">

                {
                  partnership_logos.map((logo, index) => {
                    return (
                        <span key={index}>{logo}</span>
                      )
                  })
                }

              </div>
              <div className="section_quota_partners--inner_content--logo_container--mobile">

                {
                  partnership_logos_mobile.map((logo_set, index) => {
                    return (
                        <span className={"section_quota_partners--inner_content--logo_set "+(partnership_index === index ? "active" : (partnership_index > index ? "inactive left" : "inactive right"))} key={index}>
                        {logo_set.map((logo, index2) => {
                          return (
                              <span key={index2}>{logo}</span>
                            )
                        })}</span>
                      )
                  })
                }
                {partnership_logos_mobile.length > 1 ? 
                <div className="section_quota_partners--inner_content--logo_container--pagination_box">
                  <div className="section_quota_partners--inner_content--logo_container--left_arrow" onClick={() => setPartnershipIndex(partnership_index > 0 ? partnership_index-1 : partnership_index)}>
                    <img src={mini_slideshow_left_arrow} alt="left"/></div>
                  <div className="section_quota_partners--inner_content--logo_container--numbers">{parseInt(partnership_index)+1}/{partnership_logos_mobile.length}</div>
                  <div className="section_quota_partners--inner_content--logo_container--right_arrow" onClick={() => setPartnershipIndex(partnership_index < partnership_logos_mobile.length-1 ? partnership_index+1 : partnership_index)}>
                    <img src={mini_slideshow_right_arrow} alt="right"/></div>
                </div> : ""}

              </div>
            </div>

            <div className="section_quota_partners--inner_content">
              <h4 className="section_quota_partners--inner_content--title">{section4_texts ? section4_texts[4] : ""}</h4>
              <div className="section_quota_partners--inner_content--description">{section4_texts ? section4_texts[5] : ""}</div>
              <div className="section_quota_partners--inner_content--logo_container">

                {
                  customer_logos.map((logo, index) => {
                    return (
                        <span key={index}>{logo}</span>
                      )
                  })
                }

              </div>
              <div className="section_quota_partners--inner_content--logo_container--mobile">

                {
                  customer_logos_mobile.map((logo_set, index) => {
                    return (
                        <span className={"section_quota_partners--inner_content--logo_set "+(customer_index === index ? "active" : (customer_index > index ? "inactive left" : "inactive right"))} key={index}>
                        {logo_set.map((logo, index2) => {
                          return (
                              <span key={index2}>{logo}</span>
                            )
                        })}</span>
                      )
                  })
                }
                {customer_logos_mobile.length > 1 ? 
                <div className="section_quota_partners--inner_content--logo_container--pagination_box">
                  <div className="section_quota_partners--inner_content--logo_container--left_arrow" onClick={() => setCustomerIndex(customer_index > 0 ? customer_index-1 : customer_index)}>
                    <img src={mini_slideshow_left_arrow} alt="left"/></div>
                  <div className="section_quota_partners--inner_content--logo_container--numbers">{parseInt(customer_index)+1}/{customer_logos_mobile.length}</div>
                  <div className="section_quota_partners--inner_content--logo_container--right_arrow" onClick={() => setCustomerIndex(customer_index < customer_logos_mobile.length-1 ? customer_index+1 : customer_index)}>
                    <img src={mini_slideshow_right_arrow} alt="right"/></div>
                </div> : ""}

              </div>
            </div>

          </section>




          <section className="section_people_slideshow" ref={scrollToPeopleSection}>

            <h2 className="section_people_slideshow--pretitle">{section5_texts ? <span>{section5_texts[0]}<br/>{section5_texts[1]}</span> : ""}</h2>

            <h3 className="section_people_slideshow--img_contain"><img className="section_people_slideshow--vulcan_heroes--desktop" src={disability_team} alt="disabled_people_or_vulcan_heroes"/></h3>
            <h3 className="section_people_slideshow--img_contain"><img className="section_people_slideshow--vulcan_heroes--mobile" src={disability_team__mobile} alt="disabled_people_or_vulcan_heroes"/></h3>

            <h2 className="section_people_slideshow--title">{section5_texts ? section5_texts[2] : ""} <br/>{section5_texts ? section5_texts[3] : ""}</h2>

            <div className="section_people_slideshow--slide">

              {<AliceCarousel
                  mouseTracking
                  touchTracking
                  items={slideshow_people_render}
                  paddingLeft={50}
                  paddingRight={50}
                  responsive={Configuration("responsive_people_slideshow")}
                  renderDotsItem={renderDotsItemPeople}
                  renderPrevButton={renderPrevButtonPeople}
                  renderNextButton={renderNextButtonPeople}
              />}

              {/*
                  slideshow_people_render.map((pp, index) => {
                    return (
                        <span key={index}>{pp}</span>
                      )
                  })
                */}

            </div>

          </section>




          <section className="section_sdg">
            <div className="section_sdg--img_container"><img className="section_sdg--img" src={sdg_icon} alt={sdg_title_alt} title={sdg_title_alt}/></div>
            <div className="section_sdg--img_container"><img className="section_sdg--img" src={sdg_details} alt={sdg_details_alt} title={sdg_details_alt}/></div>
          </section>

      </main>

      <Footer/>
       
      </>
    );
 // }
}

export default Home;
