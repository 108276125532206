import React, { useState, useEffect , useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Configuration from '../functions/Configuration';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import logo from '../res/logo.svg';

import section1_faster from '../res/icons/product_page-section1-faster.svg';
import section1_efficient from '../res/icons/product_page-section1-efficient.svg';
import section1_frugal from '../res/icons/product_page-section1-frugal.svg';
import section1_hr from '../res/icons/product_page-section1-hr.svg';

import section2_icon_home_automation from '../res/icons/product_page-section2-icon-home_automation.svg';
import section2_icon_voice_ai from '../res/icons/product_page-section2-icon-voice_ai.svg';
import section2_icon_maywe from '../res/icons/product_page-section2-icon-maywe.svg';
import section2_icon_live_chat from '../res/icons/product_page-section2-icon-live_chat.svg';
import section2_product_img_home_automation from '../res/icons/product_page-section2-product_img-home_automation.webp';
import section2_product_img_voice_ai from '../res/icons/product_page-section2-product_img-voice_ai.webp';
import section2_product_img_maywe from '../res/icons/product_page-section2-product_img-maywe.webp';
import section2_product_img_live_chat from '../res/icons/product_page-section2-product_img-live_chat.webp';
import section2_subicon_tts from '../res/icons/product_page-section2-subicon-tts.svg';
import section2_subicon_process from '../res/icons/product_page-section2-subicon-process.svg';
import section2_subicon_stt from '../res/icons/product_page-section2-subicon-stt.svg';
import section2_subicon_real_estate from '../res/icons/product_page-section2-subicon-real_estate.svg';
import section2_subicon_th_eng from '../res/icons/product_page-section2-subicon-th_eng.svg';
import section2_subicon_variation from '../res/icons/product_page-section2-subicon-variation.svg';
import section2_subicon_epub from '../res/icons/product_page-section2-subicon-epub.svg';
import section2_subicon_hamd from '../res/icons/product_page-section2-subicon-hamd.svg';
import section2_subicon_accuracy from '../res/icons/product_page-section2-subicon-accuracy.svg';
import section2_subicon_dashboard from '../res/icons/product_page-section2-subicon-dashboard.svg';
import section2_subicon_qa from '../res/icons/product_page-section2-subicon-qa.svg';
import section2_subicon_analysis from '../res/icons/product_page-section2-subicon-analysis.svg';
import section2_subicon_sell_deal from '../res/icons/product_page-section2-subicon-sell_deal.svg';
import section2_subicon_frugal from '../res/icons/product_page-section2-subicon-frugal.svg';
import section2_hr from '../res/icons/product_page-section2-hr.svg';

import mini_slideshow_left_arrow from '../res/icons/mini_slideshow_left_arrow.svg';
import mini_slideshow_right_arrow from '../res/icons/mini_slideshow_right_arrow.svg';

function ProductsPage() {
  const [slideshow_products, setSlideShowProducts] = useState([])
  const [slideshow_products_index, setSlideShowProductsIndex] = useState(0)

  const [overall_partners, setOverallPartners] = useState([])
  const [overall_partners_mobile, setOverallPartnersMobile] = useState([[]])
  const [overall_partners_index, setOverallPartnersIndex] = useState(0)

  const [section1_texts, setSection1Texts] = useState(undefined)
  const [section2_texts, setSection2Texts] = useState(undefined)
  const [section3_texts, setSection3Texts] = useState(undefined)

  const navigate = useNavigate();

  const scrollToProductSection = useRef();

  const navigateTo = (link) => {
       navigate(link);
   }

  const externalLink = (link) => {
       window.open(link, '_blank').focus();
   }

  const plusSlides = (next_int) => {
    const next_index = slideshow_products_index+next_int
    if (next_index < 0 || next_index >= slideshow_products.length) {
      return
    }
    setSlideShowProductsIndex(next_index)
  }

  const currentSlide = (next_index) => {
    if (next_index < 0 || next_index >= slideshow_products.length) {
      return
    }
    setSlideShowProductsIndex(next_index)
  }

  const renderDotsItem = ({ isActive }) => {
    return <span className={"carosel_dot "+(isActive ? "active" : "")}></span>
  }

  useEffect(
    () => {
      let linked_product = GetUrlHashValue("product")
      if (linked_product) {
        if (linked_product === "home_automation") {
          setSlideShowProductsIndex(0)
        } else if (linked_product === "voice_ai") {
          setSlideShowProductsIndex(1)
        } else if (linked_product === "maywe") {
          setSlideShowProductsIndex(2)
        } else if (linked_product === "live_chat") {
          setSlideShowProductsIndex(3)
        }

        scrollToProductSection.current.scrollIntoView()
      } else {
        window.scrollTo(0,0)
      }

      let text_files_dir = require.context('../res/texts/', false, /\.(txt)$/)
      let text_files_dirname =  text_files_dir.keys()
      let text_files_dir_file =  text_files_dir.keys().map(text_files_dir)
      for (let txt_file_id in text_files_dirname) {

          let file_name = text_files_dirname[txt_file_id]
          let file_path = text_files_dir_file[txt_file_id]

            fetch(file_path)
            .then((r) => r.text())
            .then(text  => {
              let array_of_chunk_per_line = text.split("\n");

              if (file_name === "./products_page__section_1__intro_section_texts.txt") {
                setSection1Texts(array_of_chunk_per_line);

              } else if (file_name === "./products_page__section_2__products_section_texts.txt") {
                setSection2Texts(array_of_chunk_per_line);
                setSlideShowProducts([
                    {
                      logo_url: section2_icon_home_automation,
                      logo_name: array_of_chunk_per_line[2],
                      name: array_of_chunk_per_line[3],
                      description: array_of_chunk_per_line[4],
                      product_image_url: section2_product_img_home_automation,
                      features: [
                        {
                          icon_url: section2_subicon_tts,
                          feature_description: array_of_chunk_per_line[5]
                        },
                        {
                          icon_url: section2_subicon_process,
                          feature_description: array_of_chunk_per_line[6]
                        },
                        {
                          icon_url: section2_subicon_stt,
                          feature_description: array_of_chunk_per_line[7]
                        },
                        {
                          icon_url: section2_subicon_real_estate,
                          feature_description: array_of_chunk_per_line[8]
                        },
                      ],
                    },
                    
                    {
                      logo_url: section2_icon_voice_ai,
                      logo_name: array_of_chunk_per_line[10],
                      name: array_of_chunk_per_line[11],
                      description: array_of_chunk_per_line[12],
                      product_image_url: section2_product_img_voice_ai,
                      features: [
                        {
                          icon_url: section2_subicon_tts,
                          feature_description: array_of_chunk_per_line[13]
                        },
                        {
                          icon_url: section2_subicon_th_eng,
                          feature_description: array_of_chunk_per_line[14]
                        },
                        {
                          icon_url: section2_subicon_variation,
                          feature_description: array_of_chunk_per_line[15]
                        },
                        {
                          icon_url: section2_subicon_epub,
                          feature_description: array_of_chunk_per_line[16]
                        },
                      ],
                    },

                    {
                      logo_url: section2_icon_maywe,
                      logo_name: array_of_chunk_per_line[18],
                      name: array_of_chunk_per_line[19],
                      description: array_of_chunk_per_line[20],
                      product_image_url: section2_product_img_maywe,
                      features: [
                        {
                          icon_url: section2_subicon_hamd,
                          feature_description: array_of_chunk_per_line[21]
                        },
                        {
                          icon_url: section2_subicon_accuracy,
                          feature_description: array_of_chunk_per_line[22]
                        },
                        {
                          icon_url: section2_subicon_dashboard,
                          feature_description: array_of_chunk_per_line[23]
                        },
                      ],
                    },

                    {
                      logo_url: section2_icon_live_chat,
                      logo_name: array_of_chunk_per_line[26],
                      name: array_of_chunk_per_line[27],
                      description: array_of_chunk_per_line[28],
                      product_image_url: section2_product_img_live_chat,
                      features: [
                        {
                          icon_url: section2_subicon_qa,
                          feature_description: array_of_chunk_per_line[29]
                        },
                        {
                          icon_url: section2_subicon_analysis,
                          feature_description: array_of_chunk_per_line[30]
                        },
                        {
                          icon_url: section2_subicon_sell_deal,
                          feature_description: array_of_chunk_per_line[31]
                        },
                        {
                          icon_url: section2_subicon_frugal,
                          feature_description: array_of_chunk_per_line[32]
                        },
                      ],
                    },
                  ])

              } else if (file_name === "./products_page__section_3__partners_section_texts.txt") {
                setSection3Texts(array_of_chunk_per_line);
              } 
              
            })  

      }

      let overall_partners_new_data_name = []
      let overall_partners_new_data_render = []

      let quota_partners_dir = require.context('../res/partners/quota_partners/', false, /\.(webp|avif)$/)
      let quota_partners_dirname =  quota_partners_dir.keys()
      let quota_partners_dir_file =  quota_partners_dir.keys().map(quota_partners_dir)
      
      for (let qp_file_id in quota_partners_dirname) {
        let qp_exact_filename = quota_partners_dirname[qp_file_id]
        let qp_filename = qp_exact_filename.substring(5, qp_exact_filename.length-5)
        overall_partners_new_data_name.push({
          alt_text: qp_filename,
        })
        overall_partners_new_data_render.push(
          <span data-value={qp_file_id}>
            <div className="section_quota_partners--inner_content--logo_center_cropper">
              <img className="section_quota_partners--inner_content--logo" src={quota_partners_dir_file[qp_file_id]} title={qp_filename} alt={qp_filename}/>
            </div>
            </span>
          )
      }

      let partnerships_dir = require.context('../res/partners/partnerships/', false, /\.(webp|avif)$/)
      let partnerships_dirname =  partnerships_dir.keys()
      let partnerships_dir_file =  partnerships_dir.keys().map(partnerships_dir)

      for (let p_file_id in partnerships_dirname) {
        let p_exact_filename = partnerships_dirname[p_file_id]
        let p_filename = p_exact_filename.substring(5, p_exact_filename.length-5)
        const old_duplicate = overall_partners_new_data_name.find((old_data) => {
          return old_data.alt_text === p_filename
        })
        if (old_duplicate) {
          continue
        }
        overall_partners_new_data_name.push({
          alt_text: p_filename,
        })
        overall_partners_new_data_render.push(
          <span data-value={p_file_id}>
            <div className="section_quota_partners--inner_content--logo_center_cropper">
              <img className="section_quota_partners--inner_content--logo" src={partnerships_dir_file[p_file_id]} title={p_filename} alt={p_filename}/>
            </div>
            </span>
          )
      }

      let customers_dir = require.context('../res/partners/customers/', false, /\.(webp|avif)$/)
      let customers_dirname =  customers_dir.keys()
      let customers_dir_file =  customers_dir.keys().map(customers_dir)

      for (let c_file_id in customers_dirname) {
        let c_exact_filename = customers_dirname[c_file_id]
        let c_filename = c_exact_filename.substring(5, c_exact_filename.length-5)
        const old_duplicate = overall_partners_new_data_name.find((old_data) => {
          return old_data.alt_text === c_filename
        })
        if (old_duplicate) {
          continue
        }
        overall_partners_new_data_name.push({
          alt_text: c_filename,
        })
        overall_partners_new_data_render.push(
          <span data-value={c_file_id}>
            <div className="section_quota_partners--inner_content--logo_center_cropper">
              <img className="section_quota_partners--inner_content--logo" src={customers_dir_file[c_file_id]} title={c_filename} alt={c_filename}/>
            </div>
            </span>
          )
      }

      setOverallPartners(overall_partners_new_data_render)

      let overall_partners_new_data_mobile = [[]]
      let o_set_id = 0
      for (let o_file_id in overall_partners_new_data_render) {
        let o_render = overall_partners_new_data_render[o_file_id]
        overall_partners_new_data_mobile[o_set_id].push(o_render)
        if ((parseInt(o_file_id)+1) % Configuration("logo_display_per_page") === 0) {
          if (parseInt(o_file_id)+1 < overall_partners_new_data_render.length) {
            o_set_id += 1
            overall_partners_new_data_mobile.push([])
          }
        }
      }

      setOverallPartnersMobile(overall_partners_new_data_mobile)
    },
    []
  )

  //https://stackoverflow.com/questions/53824116/react-hooks-usestate-array

    return (
      <>

      <header className="header--products_page">

        <Navbar isHomePage={false}/>

      </header>

      <main className="main_body">

          <section className="products_page__section_intro">
            <div className="products_page__section_intro--title_box">
            <h1 className="products_page__section_intro--title">{section1_texts ? section1_texts[0] : ""}</h1>
            <h4 className="products_page__section_intro--description">{section1_texts ? section1_texts[1] : ""}</h4>
            </div>

            <div className="products_page__section_intro--info_box">

              <div className="products_page__section_intro--info">
                
                <div className="products_page__section_intro--info--upper">
                  <img className="products_page__section_intro--info--upper--icon" src={section1_faster}/> 

                  <div className="products_page__section_intro--info--upper--title"> {section1_texts ? section1_texts[3] : ""}
                  </div> 

                </div>
                <img className="products_page__section_intro--info--middle" src={section1_hr}/>
                <div className="products_page__section_intro--info--lower">
                  {section1_texts ? section1_texts[4] : ""}
                </div>

              </div>


              <div className="products_page__section_intro--info">
                
                <div className="products_page__section_intro--info--upper">
                  <img className="products_page__section_intro--info--upper--icon" src={section1_efficient}/> 

                  <div className="products_page__section_intro--info--upper--title"> {section1_texts ? section1_texts[6] : ""}
                  </div> 

                </div>
                <img className="products_page__section_intro--info--middle" src={section1_hr}/>
                <div className="products_page__section_intro--info--lower">
                  {section1_texts ? section1_texts[7] : ""}
                </div>

              </div>


              <div className="products_page__section_intro--info">
                
                <div className="products_page__section_intro--info--upper">
                  <img className="products_page__section_intro--info--upper--icon" src={section1_frugal}/> 

                  <div className="products_page__section_intro--info--upper--title"> {section1_texts ? section1_texts[9] : ""}
                  </div> 

                </div>
                <img className="products_page__section_intro--info--middle" src={section1_hr}/>
                <div className="products_page__section_intro--info--lower">
                  {section1_texts ? section1_texts[10] : ""}
                </div>

              </div>

            </div>

          </section>



          <section className="products_page__section_products_slideshow" ref={scrollToProductSection}>

            <div className="products_page__section_products_slideshow--inner">

              <div className="products_page__section_products_slideshow--upper">

                <h3 className="products_page__section_products_slideshow--upper--title">{section2_texts ? section2_texts[0] : ""}</h3>

                <div className="products_page__section_products_slideshow--upper--icon_selectors">

                {
                  slideshow_products.map((key, index) => {
                    return (
                        <div key={index} className={"products_page__section_products_slideshow--upper--iconbox "+(index === slideshow_products_index ? "active" : "")} onClick={() => currentSlide(index)}>
                        <div className={"products_page__section_products_slideshow--upper--icon_frame "+(index === slideshow_products_index ? "active" : "")}><img className="products_page__section_products_slideshow--upper--icon" src={key.logo_url} /></div>
                        <div className="products_page__section_products_slideshow--upper--name">{key.logo_name}</div>
                        </div>
                      )
                  })
                }

                </div>

              </div>

              <div className="products_page__section_products_slideshow--lower_bigbox">
              {
                  slideshow_products.map((key, index) => {
                    return (
                        <div key={index} className={"products_page__section_products_slideshow--lower "+(index === slideshow_products_index ? "products_page__section_products_slideshow--lower--active" : (index < slideshow_products_index ? "products_page__section_products_slideshow--lower--inactive_left" : "products_page__section_products_slideshow--lower--inactive_right"))}>

                          <div className="products_page__section_products_slideshow--lower--product_img-box">
                            <div className="products_page__section_products_slideshow--lower--product_img-inner_box">
                              <div className="products_page__section_products_slideshow--lower--product_img-deep_inner_box">
                              <img className="products_page__section_products_slideshow--lower--product_img" src={key.product_image_url}/>
                              </div>
                            </div>
                          </div>

                          <div className="products_page__section_products_slideshow--lower--info_big_box">

                            <h4 className="products_page__section_products_slideshow--lower--info_big_box--name">{key.name}</h4>

                            <img className="products_page__section_products_slideshow--lower--info_big_box--hr"src={section2_hr}/>

                            <p className="products_page__section_products_slideshow--lower--info_big_box--description">{key.description}</p>

                            <div className="products_page__section_products_slideshow--lower--info_big_box--features_box">
                            {
                              key.features.map((key2, index2) => {
                                return (
                                    <div key={index2} className="products_page__section_products_slideshow--lower--info_big_box--feature">
                                      <div className="products_page__section_products_slideshow--lower--info_big_box--feature--icon_box">
                                      <img className="products_page__section_products_slideshow--lower--info_big_box--feature--icon" src={key2.icon_url}/>
                                      </div>
                                      <p className="products_page__section_products_slideshow--lower--info_big_box--feature--description">{key2.feature_description}</p>
                                    </div>
                                  )
                              })
                            }
                            </div>

                          </div>

                        </div>
                      )
                  })
              }
              </div>

              <div className="products_page__section_products_slideshow--bottom_part">
              {slideshow_products.length > 1 ? 
                <div className="section_quota_partners--inner_content--logo_container--pagination_box">
                  <div className="section_quota_partners--inner_content--logo_container--left_arrow" onClick={() => currentSlide(slideshow_products_index > 0 ? slideshow_products_index-1 : slideshow_products_index)}>
                    <img src={mini_slideshow_left_arrow} alt="left"/></div>
                  <div className="section_quota_partners--inner_content--logo_container--numbers">{parseInt(slideshow_products_index)+1}/{slideshow_products.length}</div>
                  <div className="section_quota_partners--inner_content--logo_container--right_arrow" onClick={() => currentSlide(slideshow_products_index < slideshow_products.length-1 ? slideshow_products_index+1 : slideshow_products_index)}>
                    <img src={mini_slideshow_right_arrow} alt="right"/></div>
                </div> : ""}
              </div>

            </div>

          </section>




          <section className="section_quota_partners">

            <div className="section_quota_partners--inner_content">
              <h4 className="section_quota_partners--inner_content--title">{section3_texts ? section3_texts[0] : ""}</h4>
              <div className="section_quota_partners--inner_content--description">{section3_texts ? section3_texts[1] : ""}</div>
              <div className="section_quota_partners--inner_content--logo_container">

                {
                  overall_partners.map((logo, index) => {
                    return (
                        <span key={index}>{logo}</span>
                      )
                  })
                }

              </div>
              <div className="section_quota_partners--inner_content--logo_container--mobile">

              {
                  overall_partners_mobile.map((logo_set, index) => {
                    return (
                        <span className={"section_quota_partners--inner_content--logo_set "+(overall_partners_index === index ? "active" : (overall_partners_index > index ? "inactive left" : "inactive right"))} key={index}>
                        {logo_set.map((logo, index2) => {
                          return (
                              <span key={index2}>{logo}</span>
                            )
                        })}</span>
                      )
                  })
                }
                {overall_partners_mobile.length > 1 ? 
                <div className="section_quota_partners--inner_content--logo_container--pagination_box">
                  <div className="section_quota_partners--inner_content--logo_container--left_arrow" onClick={() => setOverallPartnersIndex(overall_partners_index > 0 ? overall_partners_index-1 : overall_partners_index)}>
                    <img src={mini_slideshow_left_arrow} alt="left"/></div>
                  <div className="section_quota_partners--inner_content--logo_container--numbers">{parseInt(overall_partners_index)+1}/{overall_partners_mobile.length}</div>
                  <div className="section_quota_partners--inner_content--logo_container--right_arrow" onClick={() => setOverallPartnersIndex(overall_partners_index < overall_partners_mobile.length-1 ? overall_partners_index+1 : overall_partners_index)}>
                    <img src={mini_slideshow_right_arrow} alt="right"/></div>
                </div> : ""}

              </div>
            </div>

          </section>


      </main>

      <Footer/>
       
      </>
    );
 // }
}

export default ProductsPage;
